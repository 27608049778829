<template>
  <div class="collect">
    <div class="top">
      <img
        class="icon back"
        src="~@/assets/image/icon/back.png"
        alt="icon"
        @click="$utils.goBack"
      />
      <div class="name">
        <p class="myCollect">我的收藏</p>
        <p class="num">收藏数量：{{ list.length }}</p>
      </div>
    </div>

    <van-divider class="divider" />

    <div class="list-vessle">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :offset="5"
        @load="getList"
      >
        <div
          class="list-item"
          v-for="(item, index) in list"
          :key="index"
          @click="goDetail(item)"
        >
          <div class="info">
            <div class="left">
              <p class="title">
                {{ item.title }}
              </p>
              <p class="des">
                {{ item.summary || '暂无描述' }}
              </p>
            </div>
            <div class="right">
              <van-image class="pic" lazy-load show-loading :src="item.cover">
              </van-image>
            </div>
          </div>
          <div class="footer">
            <div class="left">
              <van-icon
                name="eye-o"
                class="icon"
                size="3.2rem"
                style="vertical-align: top"
              />
              <span class="mr">{{ item.readingNum }}</span>
              <van-icon
                name="star-o"
                class="icon"
                v-if="item.collectionStatus == 0"
              />
              <van-icon name="star" v-else />
              <span class="mr">{{ item.collectionNum }}</span>
              <van-icon name="share-o" class="icon" />
              <span>{{ item.shareNum }}</span>
            </div>
            <div class="right">
              <van-icon name="user-o" class="icon" />
              <span class="mr">{{ item.publisher }}</span>
              <van-icon name="clock-o" class="icon" />
              <span>{{
                item.updateTime && item.updateTime.split(' ')[0]
              }}</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { getCollect } from '@/api/my/collect.js'
export default {
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      size: 10,
      list: [],
    }
  },
  created() {},
  methods: {
    getList() {
      getCollect({ pageNo: this.page, pageSize: this.size }).then((res) => {
        this.page += 1
        this.list.push(...res.result.records)
        if (this.list.length == res.result.total) {
          this.finished = true
        }
        this.loading = false
      })
    },
    goDetail(query) {
      const { id } = query
      this.$router.push(`/content/detail?id=${id}`)
    },
  },
  components: {},
}
</script>

<style lang="less" scoped>
@import '~@/assets/style/common.less';
.collect {
  .top {
    margin-top: 160px;
    .icon {
      width: 2.5rem;
      margin-left: 4rem;
      vertical-align: top;
    }
    .name {
      display: inline-block;
      margin-left: 7rem;
      line-height: 5rem;
      .myCollect {
        font-weight: 400;
        font-size: 4rem;
        color: #000000;
      }
      .num {
        color: #b5b5b6;
        font-size: 2rem;
        margin-top: 5px;
      }
    }
  }
}
.list-vessle {
  padding: 0 5%;
  height: 2130px;
  .list-item {
    border-radius: 32px;
    box-shadow: 2px 2px 9px 1px rgba(3, 3, 3, 0.05),
      -2px -2px 9px 1px rgba(3, 3, 3, 0.05);
    background-color: #fff;
    padding: 48px;
    margin: 72px 0;
    .info {
      display: flex;
      .left {
        // flex: 1;
        width: 75%;
      }
      .right {
        margin: 0 0 0 24px;
      }
    }
    .pic {
      display: block;
      width: 427px;
      height: 240px;
    }
    .title {
      font-size: 54px;
      // white-space: nowrap;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .des {
      margin-top: 5px;
      font-size: 40px;
      color: @font-gray-1;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 50px;
    }
    .footer {
      font-size: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: @primary-font-color;
      margin: 20px 0 0;
      .icon {
        margin: 0 14px 0 0;
      }
      .mr {
        margin: 0 40px 0 0;
      }
    }
  }
}
</style>
